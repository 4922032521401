<!-- eslint-disable -->
<template>
  <div id="deals">
    <good-table-advance-search
      :rows="rows"
      :columns="columns"
      :columnState="columnState"
      :checkNewShipment="checkNewShipment"
      :showConvertDealButton="showConvertDealButton"
      :showShippedButton="showShippedButton"
      :showProjectedButton="showProjectedButton"
      :clickedRowData="clickedRowData"
      @getClickedRowData="getClickedRowData($event)"
      @checkNewShipment="checkNewShipment"
    />
  </div>
</template>

<script>
import GoodTableAdvanceSearch from "@/views/used-tables/GoodTableAdvanceSearch.vue";
import { VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    GoodTableAdvanceSearch,
  },
  provide: {
    link: "deals",
    filterName: "apps-todo-deals-filter",
    tagName: "apps-todo-deals-tag",
    appName: "apps-todo-deals",
    emailFilterName: "apps-email-deals-folder",
    labelName: "apps-email-deals-label",
    appEmailName: "apps-email-deals",
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "fullName",
          hidden: false,
        },
        {
          label: "Email",
          field: "email",
          hidden: false,
        },
        {
          label: "Phone",
          field: "phone",
          hidden: false,
        },
        {
          label: "Stage",
          field: "stage",
          hidden: false,
        },
        {
          label: "Status",
          field: "status",
          hidden: false,
        },
        {
          label: "Assigned To",
          field: "assignedTo",
          hidden: false,
        },
        {
          label: "Source",
          field: "source",
          hidden: false,
        },
        {
          label: "Page",
          field: "page",
          hidden: false,
        },
        {
          label: "Campaign",
          field: "campaign",
          hidden: true,
        },
        {
          label: "Last Activity",
          field: "lastActivity",
          hidden: true,
        },
        {
          label: "Follow Up",
          field: "followUp",
          hidden: true,
        },
        {
          label: "City",
          field: "city",
          hidden: true,
        },
        {
          label: "Country",
          field: "country",
          hidden: true,
        },
        {
          label: "Lead Age",
          field: "leadAge",
          hidden: true,
        },
        {
          label: "Converted at",
          field: "convertedAt",
          hidden: false,
        },
        {
          label: "Converted by",
          field: "convertedBy",
          hidden: false,
        },
      ],
      rows: [],
      updatedColsDeals: [],
      clickedRowData: [],
      shipmentsRows: [],
      projectsRows: [],
      showConvertDealButton: false,
      showProjectedButton: false,
      showShippedButton: false,
    };
  },
  mounted() {
    // eslint-disable-next-line operator-linebreak
    this.columns =
      JSON.parse(localStorage.getItem("updatedColsDeals")) || this.columns;

    // eslint-disable-next-line operator-linebreak
    this.rows = JSON.parse(localStorage.getItem("dealsRows")) || this.rows;

    this.$router.push("/deals").catch(() => {});
  },
  methods: {
    columnState() {
      this.updatedColsDeals = [];
      this.columns.forEach((column, index) => {
        this.updatedColsDeals.push(this.columns[index]);
        localStorage.setItem(
          "updatedColsDeals",
          // eslint-disable-next-line comma-dangle
          JSON.stringify(this.updatedColsDeals)
        );
      });
    },
    getClickedRowData(event) {
      this.clickedRowData = event.row;
    },
    checkNewShipment() {
      // eslint-disable-next-line operator-linebreak
      this.shipmentsRows =
        // eslint-disable-next-line operator-linebreak
        JSON.parse(localStorage.getItem("shippmentsRows")) ||
        this.shipmentsRows;

      // eslint-disable-next-line operator-linebreak
      this.projectsRows =
        // eslint-disable-next-line operator-linebreak
        JSON.parse(localStorage.getItem("projectsRows")) || this.projectsRows;
      if (
        // eslint-disable-next-line arrow-parens
        this.shipmentsRows.filter((row) => row.id === this.clickedRowData.id)
          .length > 0
      ) {
        this.showConvertDealButton = false;
        this.showProjectedButton = false;
        this.showShippedButton = true;
      } else if (
        // eslint-disable-next-line arrow-parens
        this.projectsRows.filter((row) => row.id === this.clickedRowData.id)
          .length > 0
      ) {
        this.showConvertDealButton = false;
        this.showProjectedButton = true;
        this.showShippedButton = false;
      } else {
        this.showConvertDealButton = true;
        this.showProjectedButton = false;
        this.showShippedButton = false;
      }
    },
    changeDealsBtnStatus() {
      this.showConvertDealButton = false;
      this.showProjectedButton = false;
      this.showShippedButton = true;
    },
    changeDealsProjectsBtnStatus() {
      this.showConvertDealButton = false;
      this.showProjectedButton = true;
      this.showShippedButton = false;
    },
  },
};
</script>

<style>
#deals .right-header-col a {
  padding-right: 0;
}
#deals .reset-deals {
  padding: 5px 10px;
  display: flex;
}
#deals .reset-shipped {
  padding: 5px 10px;
  display: flex;
}
</style>
